import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
// import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

import withRouter from "../Common/withRouter";
import {
  CANDIDATURE_PAGE,
  DASHBOARD_PAGE,
  PORTAIL_PAGE,
  SYNCHRONISATION_PAGE,
  TRANSACTION_PAGE,
} from "../../routes/routeConstants";
import { jwtDecode } from "jwt-decode";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathName: this.props.router.location.pathname,
    };
  }

  componentDidMount() {
    this.initMenu();
  }

  UNSAFE_componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
    if (
      this.props.router.location.pathname !== prevProps.router.location.pathname
    ) {
      this.setState({ pathName: this.props.router.location.pathname }, () => {
        this.initMenu();
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");
    const { pathName } = this.state;

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  hasRole = (role) => {
    const authUser = localStorage.getItem("authUser");
    const parsedAuthUser = JSON.parse(authUser);
    const decoded = jwtDecode(parsedAuthUser?.accessToken);
    const roles = decoded?.roles ?? [];
    console.log(roles);
    const found = [role]?.some((r) => roles?.indexOf(r) >= 0);
    return found;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t("Menu")}</li>
            {this.hasRole("ROLE_CANDIDAT") && (
              <li>
                <Link to={PORTAIL_PAGE} className="waves-effect">
                  <i className="ri-team-line"></i>
                  {/* <span className="badge rounded-pill bg-success float-end">3</span> */}
                  <span className="ms-1">{this.props.t("menu.profile")}</span>
                </Link>
              </li>
            )}
            {(this.hasRole("ROLE_ADMIN") || this.hasRole("ROLE_SUPER_ADMIN") )&& (
              <li>
                <Link to={DASHBOARD_PAGE} className="waves-effect">
                  <i className="ri-dashboard-line"></i>
                  {/* <span className="badge rounded-pill bg-success float-end">3</span> */}
                  <span className="ms-1">
                    {this.props.t("dashboard.dashboard")}
                  </span>
                </Link>
              </li>
            )}
            {(this.hasRole("ROLE_ADMIN") || this.hasRole("ROLE_SUPER_ADMIN")) && (
              <li>
                <Link to={TRANSACTION_PAGE} className="waves-effect">
                  <i className="ri-money-dollar-circle-line"></i>
                  {/* <span className="badge rounded-pill bg-success float-end">3</span> */}
                  <span className="ms-1">
                    {this.props.t("transaction.transactions")}
                  </span>
                </Link>
              </li>
            )}
            {(this.hasRole("ROLE_ADMIN") || this.hasRole("ROLE_SUPER_ADMIN")) && (
              <li>
                <Link to={CANDIDATURE_PAGE} className="waves-effect">
                  <i className="ri-group-line"></i>
                  <span className="ms-1">
                    {this.props.t("inscription.candidatures")}
                  </span>
                </Link>
              </li>
            )}
            {this.hasRole("ROLE_SUPER_ADMIN") && (
              <li>
                <Link to={SYNCHRONISATION_PAGE} className="waves-effect">
                  <i className="ri-refresh-line"></i>
                  <span className="ms-1">
                    {this.props.t("menu.synchronisation")}
                  </span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(SidebarContent)
);
