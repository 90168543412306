import i18n from "i18next";
import { initReactI18next } from "react-i18next";



//translations
const resources = {

  fr: {
    translation: {
      menu: {
        profile: "ESPACE CANDIDAT",
        synchronisation: "Synchronisation des données"
      },
      section: {
        fiche: "FICHE DE CANDIDATURE",
        identification: "IDENTIFICATION DU CANDIDAT",
        documents: "TELECHARGEMENT DES DOCUMENTS PHYSIQUES",
        confirmation: "CONFIRMATION",
        payment: "PAIEMENT",
        paymentCandid: "Frais de candidature (Obligatoire)",
        paymentCertificat: "Frais de certificat",
        payment1:
          "PAIEMENT pour délivrance du CERTIFICAT D'ATTESTATION de HANDICAP",
        attachRecu: "Reçu de paiement",
        avis: "AVIS DIRECTION",
      },
      inscription: {
        note: "Note résultat visite medicale",
        confirmed: "Je reconnais que les données fournies dans le cadre de l'inscription sont correctes et que toute fausse déclaration peut entrainer l'annulation de ma candidature et meme de mon admission sans préjudice de poursuite judiciaire.",
        typeDiplome: "Diplome",
        niveauEtude: "Niveau d'étude",
        diplomeAutre: "Si autre, préciser: ",
        typeConnaissance: "Avez-vous une connaissance en: ",
        createdDate: "Date d'inscription",
        name: "Nom & Prénoms",
        numero: "Code",
        nom: "Nom",
        prenom: "Prénoms",
        mere: "Nom & Prénoms de la Mère ",
        pere: "Nom & Prénoms du Père ",
        lieunais: "Lieu de naissance",
        datenaiss: "Date de naissance",
        genre: "Sexe",
        email: "Email",
        nationalite: "Nationalité",
        situation: "Situation matrimoniale",
        nbrEnfants: "Nombre d’enfant",
        telephone: "Contact téléphonique",
        lieuResidence: "Lieu de résidence",
        numCmu: "Numéro CMU",
        numCNI: "Numéro CNI / PASSPORT",
        typeHandi: "Type de handicap",
        photo: "Photo numérique",
        documentType: "Document type",
        filename: "Nom de fichier",
        operateur: "Opérateur",
        login: "Identifiant (Numéro de téléphone)",
        password: "Mot de passe",
        numeropay: "Numéro",
        amount: "Montant (XOF)",
        autreTypeHandicap: "Si autre type de handicap, précisez le:",
        candidatures: "Candidatures",
        paiement: "Frais Condidature",
        paiementCertificat: "Frais Certificat",
        inscriptionStatus: "Status",
        comment: "Commentaire",
        apte: "APTE VISITE MEDICALE",
        inapte: "INAPTE VISITE MEDICALE",
        transProgTitle: "Transaction en cours",
        paieReussi: "Votre paiement a été fait avec succés",
        paieEchoue: "Votre paiement précédent a échoué, réessayez.",
        paieCertificatReussie:
          "Paiement du CERTIFICAT D'ATTESTATION de HANDICAP a été fait avec succés",
        paieCertificatEchoue:
          "Paiement du CERTIFICAT D'ATTESTATION de HANDICAP a été échoué, réessayez.",
      },
      transaction: {
        showing: "Affichage de {{start}} à {{end}} sur {{total}} résultats (page {{currentPage}} de {{totalPages}})",
        transactions: "Transactions Financiéres",
        id: "Id",
        externalId: "External id",
        date: "Date de paiement",
        status: "Status",
        telephone: "N° téléphone",
        name: "From name",
        amount: "Montant (CFA)",
        typePaiment: "Paiement",
        createdDate: "Date de Paiement  ",
      },
      login: {
        title: "Connectez-vous pour continuer sur HANDI-DPPH.",
        username: "Login",
        password: "Mot de passe",
        rememberme: "Remember Me",
        forgetpass: "Forget your password?",
      },
      message: {
        required: "Champ obligatoire",
        emailInvalid: "Adresse email invalide",
        invalidEmail: "Email invalide",
        amountMin: "Le montant saisi n'est pas correct.",
        phoneFormat:
          "Le numéro de téléphone doit contenir exactement 10 chiffres.",
        phoneExceeded:
          "Le numéro de téléphone ne peut pas dépasser 10 chiffres.",
        password: "Minimum huit caractères, au moins une lettre et un chiffre",
        passwordFormat:
          "Le mot de passe doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.",
        saveSucces: "Votre compte crée avec succés",
        saveErreur: "Erreur identifiant déjà utilisé.",
        confirm: "Votre décision sera enregistrée, veuillez confirmer ?",
        confirmSuccess: "Opération réussi",
      },
      actions: {
        title: "Action",
        save: "Enregistrer",
        delete: "Supprimer",
        edit: "Modifier",
        login: "Se connecter",
        logout: "Logout",
        downloadTemp: "Template",
        export: "Exporter",
        upload: "Données",
        close: "Annuler",
        cancel: "Annuler",
        confirm: "Confirmer",
        new: "Créer",
        payer: "Payer",
        refresh: "Rafraîchir",
        history: "Historique",
        details: "Détails",
        smsHistory: "Historique des Messages",
      },
      text: {
        confirmation: "Confirmation",
        msgDelete: "Voulez vous supprimer cette ligne ?",
      },
      sms: {
        date: "Date",
        action: "Action",
        status: "Status",
        telephone: "Telephone",
      },
      history: {
        createdDate: "Date",
        statusAfter: "Status Apres",
        statusBefore: "Status Avant",
      },
      dashboard: {
        dashboard: "Tableau de bord",
        type: "Type",
        totalMale: "Totale des hommes",
        totalFemale: "Totale des femmes",
        totalPerType: "Total par type",
        status1Count: "En Cours d'analyse",
        status2Count: "Candidatures retenues",
        status3Count: "Candidatures non retenues",
        status4Count: "Candidatures admis",
        status5Count: "Candidatures non admis",
      },
      filter: {
        showFilters: "Filtrer",
        hideFilters: "Masquer le filtre",
        resetFilters: "Effacer le filtre",
      }, 
    },
  },
  // it: {
  //   translation: translationIT
  // },
  //  rs: {
  //   translation: translationRS
  // },
  //  sp: {
  //   translation: translationSP
  // },
  eng: {
    translation: {
      Gestpanne: "Issues",
      menu: {
        dashboard: "Dashboard",
      },
      actions: {
        login: "Login",
        logout: "Logout",
      },
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "fr",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
