import { useState } from "react";
import config from "../helpers/config";
import { MobilePDFReader } from "react-read-pdf";

function FicheInscriptionPage() {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  return (
    <div style={{ overflow: "scroll", height: 600 }}>
      <MobilePDFReader
        url={`${config.baseURL}/api/payments/previewReportInscription/${id}`}
      />
    </div>
  );
}

export default FicheInscriptionPage;
