import React, { useEffect, useRef, useState } from 'react';
import { Table, Container, Input, Button, Pagination, PaginationItem, PaginationLink, Row, Col, Card, CardHeader, CardBody, Alert } from 'reactstrap';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaDatabase, FaSearch, FaSync, FaUpload } from 'react-icons/fa';
import { faDatabase, faInfoCircle, faSearch, faSync, faUpload } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { transactionActions } from '../../saga/transactionSlice';

/**
 * Synchronisation component for identifying and fixing failed transactions in Handi DPPH compared to the MTN portal.
 * 
 * This component allows users to:
 * - Upload an Excel file containing transaction data from the MTN portal.
 * - View and search through the uploaded Excel data.
 * - Load and view transaction data from the Handi DPPH backend.
 * - Synchronize the data between the MTN portal and Handi DPPH.
 * 
 * Features:
 * - File upload with validation for Excel files (.xlsx, .xls).
 * - Data parsing and filtering from the uploaded Excel file.
 * - Pagination for both Excel and backend data.
 * - Search functionality to filter through the Excel data.
 * - Synchronization button to post the Excel data to the backend and reload the backend data.
 * 
 * @component
 */
const Synchronisation = () => {
    const [excelData, setExcelData] = useState([]);
    const [backendData, setBackendData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [excelPageNumber, setExcelPageNumber] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const { t } = useTranslation();
    const {
        transactions,
        isFetching,
        totalPages,
        totalElements,
        pageNumber,
        pageSize,
        error,
    } = useSelector((state) => state.transaction);

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedTypes = [
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel'
            ];
            if (!allowedTypes.includes(file.type)) {
                Swal.fire({
                    icon: 'error',
                    title: t('product.invalidFileType'),
                    text: t('product.excelOnly'),
                });
                return;
            }
            const reader = new FileReader();
            try {
                reader.onload = (event) => {
                    const binaryStr = event.target.result;
                    const workbook = XLSX.read(binaryStr, { type: 'binary' });
                    const worksheet = workbook.Sheets[workbook.SheetNames[0]];

                    // Get all data first
                    const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                    // Skip header row and map only needed columns
                    const filteredData = rawData.slice(1).map(row => ({
                        "External ID": row[1],  // Assuming first column
                        "Date": row[2],        // Second column
                        "Status": row[3],      // Third column
                        "Téléphone": row[6],        // Fourth column
                        "Nom & Prénoms": row[8],        // Fifth column
                        "Montant payé": row[48],  // Sixth column
                    }));

                    setExcelData(filteredData);
                };

                reader.readAsBinaryString(file);
            } catch (error) {
                console.error('Error parsing Excel:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur!',
                    text: "Erreur lors de l'importation des transactions. Veuillez réessayer.",
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#dc3545'
                });
            } finally {
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            }
        }
    };

    useEffect(() => {
        const mappedData = excelData ?? [].map(row => ({
            externalId: row["External ID"],
            date: null,
            status: null,
            from: null,
            fromName: null,
            fromAccount: null, // Using phone number as account
            amount: null // Clean amount string
        }));

        dispatch(
            transactionActions.loadFailedTransactions({
                query: mappedData,
                page: currentPage,
                size: pageSize,
            })
        );
    }, [currentPage]);

    const loadBackendData = () => {
        const mappedData = excelData.map(row => ({
            externalId: row["External ID"],
            date: null,
            status: null,
            from: null,
            fromName: null,
            fromAccount: null, // Using phone number as account
            amount: null // Clean amount string
        }));

        dispatch(
            transactionActions.loadFailedTransactions({
                query: mappedData,
                page: currentPage,
                size: pageSize,
            })
        );
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSync = async () => {
        const result = await Swal.fire({
            title: 'Confirmation',
            text: 'Voulez-vous synchroniser les données MTN avec HANDI-DPPH ?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Oui, synchroniser',
            cancelButtonText: 'Annuler'
        });
    
        if (result.isConfirmed) {
            const mappedData = excelData.map(row => ({
                externalId: row["External ID"],
                date: null,
                status: null,
                from: null,
                fromName: null,
                fromAccount: null, // Using phone number as account
                amount: null // Clean amount string
            }));
            dispatch(
                transactionActions.syncData({
                    query: mappedData,
                    onSuccess: () => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Synchronisation réussie',
                            text: 'Les données ont été synchronisées avec succès',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#28a745'
                        });
                        loadBackendData(); // Reload backend data after sync
                    },
                    onError: (error) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Erreur de synchronisation',
                            text: error?.message || 'Une erreur est survenue lors de la synchronisation',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#dc3545'
                        });
                    }
                })
            );
        }
    };

    const filteredExcelData = excelData.filter(row =>
        Object.values(row).some(value =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const currentExcelData = filteredExcelData.slice(
        (excelPageNumber - 1) * pageSize,
        excelPageNumber * pageSize
    );

    return (
        <div className="page-content">
            <Container fluid>
                {/* Page Header */}
                <Card className="mb-4">
                    <CardHeader>
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <FontAwesomeIcon icon={faSync} size="2x" className="text-primary" />
                            </div>
                            <div>
                                <h4 className="mb-1">Synchronisation MTN vs HANDI-DPPH</h4>
                                <p className="text-muted mb-0">
                                    Comparez et synchronisez les données entre MTN et HANDI-DPPH
                                </p>
                            </div>
                        </div>
                    </CardHeader>
                </Card>
                <Alert color="info" className="mb-4">
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faInfoCircle} className="me-2" size="lg" />
                        <div>
                            <h5 className="mb-1">Objectif de la synchronisation</h5>
                            <p className="mb-0">
                                Cette page vous permet d'identifier les transactions échouées dans Handi-DPPH par rapport au portail MTN et de les corriger.
                                Veuillez télécharger le fichier Excel MTN et comparer avec les données Handi-DPPH pour détecter les anomalies.
                            </p>
                        </div>
                    </div>
                </Alert>
                <Card className="mb-4">
                    <CardBody>
                        <Row className="align-items-center">
                            <Col md={3}>
                                <Button color="primary" className="w-100" onClick={() => fileInputRef.current.click()}>
                                    <FontAwesomeIcon icon={faUpload} className="me-2" />
                                    Choisir un fichier
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleFileUpload}
                                        accept=".xlsx,.xls"
                                        style={{ display: 'none' }}
                                    />
                                </Button>
                                <small className="text-muted d-block mt-2">Excel files only (.xlsx, .xls)</small>
                            </Col>

                            <Col md={4} className="mx-auto">
                                <div className="position-relative">
                                    <FontAwesomeIcon icon={faSearch} className="position-absolute ms-3" style={{ top: '12px' }} />
                                    <Input
                                        type="search"
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="ps-5"
                                    />
                                </div>
                            </Col>

                            <Col md={4} className="text-end">
                                <Button
                                    color="primary"
                                    className="me-2"
                                    onClick={loadBackendData}
                                    disabled={isFetching || excelData.length === 0}
                                    title="Identifier les transactions à corriger"
                                >
                                    <FontAwesomeIcon icon={faDatabase} className="me-2" />
                                    Charger les données
                                </Button>
                                <Button
                                    color="success"
                                    onClick={handleSync}
                                    disabled={!transactions.length || isFetching || excelData.length === 0}
                                    title="Synchroniser"
                                >
                                    <FontAwesomeIcon icon={faSync} className="me-2" />
                                    Synchroniser
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Row>
                    <Col md={6}>
                        <Card>
                            <CardHeader className="d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Extraction MTN</h5>
                                <small className="text-muted">{excelData.length} records</small>
                            </CardHeader>
                            <CardBody>
                                <div style={{ overflowX: 'auto', maxHeight: '60vh' }}>
                                    {excelData.length === 0 ? (
                                        <div className="text-center p-3 text-muted">
                                            Aucune donnée disponible
                                        </div>
                                    ) : (
                                        <Table bordered hover responsive className="mb-0">
                                            <thead>
                                                <tr>
                                                    {currentExcelData.length > 0 && Object.keys(currentExcelData[0]).map((header, index) => (
                                                        <th key={index} style={{ whiteSpace: 'nowrap' }}>{header}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentExcelData.map((row, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {Object.values(row).map((cell, cellIndex) => (
                                                            <td key={cellIndex}>{cell}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <small className="text-muted">
                                        {t('transaction.showing', {
                                            start: ((excelPageNumber - 1)  * pageSize) + 1,
                                            end: Math.min((excelPageNumber + 1) * pageSize, excelData.length),
                                            total: excelData.length,
                                            currentPage: excelPageNumber,
                                            totalPages: Math.ceil(excelData.length / pageSize)
                                        })}
                                    </small>
                                    <Pagination className="mb-0">
                                        <PaginationItem disabled={excelPageNumber <= 1}>
                                            <PaginationLink previous onClick={e => {
                                                e.preventDefault();
                                                setExcelPageNumber(excelPageNumber - 1);
                                            }} />
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink className="disabled">
                                                {excelPageNumber} / {Math.ceil(excelData.length / pageSize)}
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem disabled={excelPageNumber >= Math.ceil(excelData.length / pageSize)}>
                                            <PaginationLink next onClick={e => {
                                                e.preventDefault();
                                                setExcelPageNumber(excelPageNumber + 1);
                                            }} />
                                        </PaginationItem>
                                    </Pagination>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <CardHeader className="d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Données HANDI-DPPH</h5>
                                <small className="text-muted">{transactions?.length || 0} enregistrements</small>
                            </CardHeader>
                            <CardBody>
                                <div style={{ overflowX: 'auto', maxHeight: '60vh' }}>
                                    {isFetching ? (
                                        <div className="text-center p-3">
                                            <span className="spinner-border spinner-border-sm me-2" />
                                            Chargement...
                                        </div>
                                    ) : transactions?.length === 0 ? (
                                        <div className="text-center p-3 text-muted">
                                            Aucune transaction à corriger
                                        </div>
                                    ) : (
                                        <Table bordered hover responsive className="mb-0">
                                            <thead>
                                                <tr>
                                                    <th>External ID</th>
                                                    <th>Date</th>
                                                    <th>Montant</th>
                                                    <th>Status</th>
                                                    <th>Type Paiement</th>
                                                    <th>Numéro</th>
                                                    <th>Nom</th>
                                                    <th>Prénom</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {transactions?.map((transaction, index) => (
                                                    <tr key={index}>
                                                        <td>{transaction.externalId}</td>
                                                        <td>{transaction.createdDate}</td>
                                                        <td>{`${transaction.amount} ${transaction.currency}`}</td>
                                                        <td>
                                                            <span className={`badge bg-${transaction.status === 'SUCCESSFUL' ? 'success' :
                                                                transaction.status === 'PENDING' ? 'warning' : 'danger'
                                                                }`}>
                                                                {transaction.status}
                                                            </span>
                                                        </td>
                                                        <td>{transaction.typePaiment}</td>
                                                        <td>{transaction.inscriptionNumero}</td>
                                                        <td>{transaction.inscriptionNom}</td>
                                                        <td>{transaction.inscriptionPrenom}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <small className="text-muted">
                                        {t('transaction.showing', {
                                            start: (currentPage - 1) * pageSize + 1,
                                            end: Math.min(currentPage * pageSize, totalElements),
                                            total: totalElements,
                                            currentPage: currentPage,
                                            totalPages: totalPages
                                        })}
                                    </small>
                                    <Pagination className="mb-0">
                                        <PaginationItem disabled={pageNumber <= 1}>
                                            <PaginationLink previous onClick={e => {
                                                e.preventDefault();
                                                handlePageChange(pageNumber - 1);
                                            }} />
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink className="disabled">
                                                {pageNumber} / {totalPages}
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem disabled={pageNumber >= totalPages}>
                                            <PaginationLink next onClick={e => {
                                                e.preventDefault();
                                                handlePageChange(pageNumber + 1);
                                            }} />
                                        </PaginationItem>
                                    </Pagination>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Synchronisation;