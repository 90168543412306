import { useEffect, useState } from "react";
import config from "../helpers/config";
import { MobilePDFReader } from "react-read-pdf";

function RecuPaiementPage() {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  return (
    <div style={{ overflow: "scroll", height: 600 }}>
      <MobilePDFReader
        url={`${config.baseURL}/api/payments/previewReportPayment/${id}`}
      />
    </div>
  );
}

export default RecuPaiementPage;
