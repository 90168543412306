export const REGISTER_FORM = "/register";
export const REGISTER_EDIT = "/main/register/edit/:id";
export const LOGIN_FORM = "/login";
export const PORTAIL_PAGE = "/portail/:numero?";
export const TRANSACTION_PAGE = "/transaction";
export const CANDIDATURE_PAGE = "/candidature";
export const RECU_PAIEMENT_PAGE = "/scanQrPaiement";
export const FICHE_INSCRIPTION_PAGE = "/scanQrInscrip";
export const DASHBOARD_PAGE = "/main/dashboard";
export const SYNCHRONISATION_PAGE = "/synchronisation";

