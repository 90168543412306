import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userActions } from "../../saga/userSlice";
import React from "react";

import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";

// Redux
import { Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import logodark from "../../assets/images/logo.jpeg";
import logolight from "../../assets/images/logo.jpeg";
import { useTranslation } from "react-i18next";
import { decodeToken } from "react-jwt";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");
  const { isFetching, error } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add("auth-body-bg");
    return () => {
      document.body.classList.remove("auth-body-bg");
    };
  }, []);

  const hasRoleAdmin = (data) => {
    const decodedToken = decodeToken(data.accessToken);
    const roles = decodedToken?.roles ?? [];
    console.log(roles);
    const found = ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"]?.some((r) => roles?.indexOf(r) >= 0);
    return found;
  };

  const formik = useFormik({
    initialValues: { username: "", password: "" },
    onSubmit: (values) => {
      dispatch(
        userActions.login({
          user: values,
          onSuccess: (data) => {
            setLoading(true);
            setTimeout(() => {
              // setLoading(false);
              hasRoleAdmin(data)
                ? navigate(
                  `/main/dashboard?token=${localStorage.getItem("token")}`
                )
                : navigate(
                  `/portail/${data?.numero}?token=${localStorage.getItem(
                    "token"
                  )}`
                );
            }, 3000);
          },
        })
      );
    },
  });

  return (
    <React.Fragment>
      {isFetching || loading ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/" className="">
                                <img
                                  src={logodark}
                                  alt=""
                                  height="120"
                                  className="auth-logo logo-dark mx-auto"
                                />
                                <img
                                  src={logolight}
                                  alt=""
                                  height="60"
                                  className="auth-logo logo-light mx-auto"
                                />
                              </Link>
                            </div>
                            <h4 className="font-size-18 mt-4 color-ci">
                              Bienvenue
                            </h4>
                            <p className="color-ci">{t("login.title")}</p>
                            <p className="color-hint">Pour toutes vos suggestions, envoyez-nous un mail à l'adresse dpphrecrutement@gmail.com </p>
                          </div>
                          {error && error ? (
                            <Alert color="danger">{error}</Alert>
                          ) : null}
                          <div className="p-2 mt-5">
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={formik.handleSubmit}
                            >
                              <div className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="username">
                                  {t("login.username")}
                                </Label>
                                <AvField
                                  {...formik.getFieldProps("username")}
                                  type="text"
                                  className="form-control"
                                  id="username"
                                  placeholder={t("login.username")}
                                  validate={{ required: { value: true } }}
                                />
                              </div>

                              <div className="auth-form-group-custom mb-4">
                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="userpassword">
                                  {t("login.password")}
                                </Label>
                                <AvField
                                  {...formik.getFieldProps("password")}
                                  type="password"
                                  className="form-control"
                                  id="userpassword"
                                  placeholder={t("login.password")}
                                  validate={{ required: { value: true } }}
                                />
                              </div>

                              {/* <div className="form-check">
                                <Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customControlInline"
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="customControlInline"
                                >
                                  {t("login.rememberme")}
                                </Label>
                              </div> */}

                              <div className="mt-4 text-center">
                                <Button
                                  color="success"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                  // disabled={formik.values.username !== 'admindpph'}
                                >
                                  {t("actions.login")}
                                </Button>
                              </div>

                              {/* <div className="mt-4 text-center">
                                <Link
                                  to="/forgot-password"
                                  className="text-muted"
                                >
                                  <i className="mdi mdi-lock me-1"></i>{" "}
                                  {t("login.forgetpass")}
                                </Link>
                              </div> */}
                            </AvForm>
                          </div>

                          <div className="mt-5 text-center color-ci">
                            {/* <p style={{ fontSize: 14 }}>
                              Vous n'avez pas de compte ?{" "}
                              <Link
                                to="/register"

                                style={{ color: "white" }}
                              >
                                {" "}
                                S'inscrire{" "}
                              </Link>{" "}
                            </p> */}
                            <p style={{ fontSize: 14}}>
                              Développé par{" "}
                              <a href="https://ideracloud.com" target="_blank" style={{ color: "white" }}>
                                IDERACLOUD
                              </a>{" "}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="authentication-bg">
                  {/* <div className="bg-overlay"></div> */}
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col sm={6} style={{ fontSize: "14px" }}>
                {new Date().getFullYear()} © HANDI-DPPH
              </Col>
              <Col sm={6} style={{ fontSize: "14px" }}>
                <div className="text-sm-end d-none d-sm-block">
                  Développé par <a href="https://ideracloud.com" target="_blank">IDERACLOUD</a>
                </div>
              </Col>
            </Row> */}
          </Container>

        </div>

      )}
    </React.Fragment>
  );
}

export default Login;
