import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import appReducer from "./reducers";
import userSlice from "../saga/userSlice";
import { rootSaga } from "saga-slice";
import inscriptionSlice from "../saga/inscriptionSlice";
import dataSlice from "../saga/dataSlice";
import transactionSlice from "../saga/transactionSlice";
import storage from 'redux-persist/lib/storage';
import persistStore from "redux-persist/es/persistStore";
import { persistReducer } from "redux-persist";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'] // Only persist auth state
};
const modules = [userSlice, inscriptionSlice, dataSlice, transactionSlice];
const persistedReducer = persistReducer(persistConfig, appReducer);



const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga(modules));

export { store, persistor };