import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import {
  DASHBOARD_PAGE,
  PORTAIL_PAGE,
  REGISTER_FORM,
  TRANSACTION_PAGE,
  RECU_PAIEMENT_PAGE,
  FICHE_INSCRIPTION_PAGE,
  CANDIDATURE_PAGE,
  SYNCHRONISATION_PAGE,
} from "./routeConstants";
import Inscription from "../modules/inscription";
import UserDetails from "../pages/User";
import Portail from "../modules/portail";
import Transactions from "../modules/transaction";
import Candidatures from "../modules/inscription/Candidatures";
import Dashboard from "../modules/dashboard";
import RecuPaiementPage from "../modules/RecuPaiementPage";
import FicheInscriptionPage from "../modules/FicheInscriptionPage";
import Synchronisation from "../modules/synchronisation";

const authProtectedRoutes = [
  // { path: DASHBOARD_PAGE, component: <DashboardPage /> },
  //{ path: "/main/qualite/form", component: <FormElements /> },
  // Gestion des pannes et arrets
  {
    path: PORTAIL_PAGE,
    component: <Portail />,
    roles: ["ROLE_CANDIDAT", "ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
  },
  {
    path: TRANSACTION_PAGE,
    component: <Transactions />,
    roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
  },
  {
    path: CANDIDATURE_PAGE,
    component: <Candidatures />,
    roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
  },
  {
    path: SYNCHRONISATION_PAGE,
    component: <Synchronisation />,
    roles: ["ROLE_SUPER_ADMIN"],
  },
  {
    path: DASHBOARD_PAGE,
    component: <Dashboard />,
    roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
  },
  { path: "/userdetails", component: <UserDetails /> },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to="/login" /> },
];

const publicRoutes = [
  { path: "/", exact: true, component: <Navigate to="/login" /> },

  // { path: REGISTER_FORM, component: <Inscription /> },
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: RECU_PAIEMENT_PAGE, component: <RecuPaiementPage /> },
  { path: FICHE_INSCRIPTION_PAGE, component: <FicheInscriptionPage /> },
];

export { authProtectedRoutes, publicRoutes };
